import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import fetch from "./../../fetch";
import { baseURL, commonHeaders } from "../../../../Containers/Confiq/index.js";
import {
  DOWNLOAD_DUMP,
  DOWNLOAD_DUMP_SUCCESS,
  DOWNLOAD_DUMP_ERROR,
} from "./../../actionType.js";
import apiCall from "./../../fetch";
import cogoToast from "cogo-toast";

function* DownloadDump({ payload }) {
  //console.log('11payload',payload);
  
  try {
    let params={};
     params["where"] = payload;

    const response = yield call(apiCall, {
      url: `${baseURL}/parse/classes/golfbookings`,
      method: "GET",
      params:params,
      headers: { ...commonHeaders },
    });

    if (response?.status === 200) {
      yield put({ type: DOWNLOAD_DUMP_SUCCESS, payload: response });
    } else {
      cogoToast?.error(response?.response?.data?.error);
      yield put({ type: DOWNLOAD_DUMP_ERROR, payload: response.error });
    }
  } catch (error) {
    yield put({ type: DOWNLOAD_DUMP_ERROR, payload: error });
  }
}

export function* watchDownloadDump() {
  yield takeEvery(DOWNLOAD_DUMP, DownloadDump);
}

function* downloadDumpSaga() {
  yield all([fork(watchDownloadDump)]);
}

export default downloadDumpSaga;
